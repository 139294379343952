import React from 'react';
import { WysiwygEditor, convertJsonToWysiwygValue } from 'onsolve-ui-components';
import { t } from '@lingui/macro';
import styles from './Policies.module.scss';
import DotsLoadingSpinner from '@corecomponents/Loading/DotsLoadingSpinner';
import getClassName from '@utils/getClassName';
import { useThemeContext } from '@context/ThemeProvider';
import { policiesTitle, PolicyDisplayOption, useCustomPoliciesContext, } from '@context/CustomPoliciesProvider';
import { useParams } from 'react-router-dom';
const Policies = () => {
    const { customPolicies, isCustomPoliciesLoading } = useCustomPoliciesContext();
    const { themeLogo, themeTitle } = useThemeContext();
    const [rootClass, getChildClass] = getClassName({
        styles,
        rootClass: 'policies',
    });
    const { selectedPolicy } = useParams();
    const renderRichText = ({ value }) => {
        if (!value) {
            return null;
        }
        return (<WysiwygEditor className={styles.richText} editorValue={convertJsonToWysiwygValue(value)} readOnly noBorder scrollable={false}/>);
    };
    const renderLink = (policyTextValue, policyTitle) => {
        const { value, id } = policyTextValue;
        const policyType = policiesTitle.filter((policies) => policies.id === policyTitle)[0]?.value;
        return (<div className={getChildClass('linkWrapper')} key={id}>
                <div>{t `Follow the external link to review the ${policyType} for ${themeTitle}`}.</div>
                <a href={value} className={getChildClass('link')}>
                    {value}
                </a>
            </div>);
    };
    if (isCustomPoliciesLoading) {
        return <DotsLoadingSpinner className={getChildClass('spinner')}/>;
    }
    return (<div className={rootClass}>
            <div className={getChildClass('content')}>
                <div className={getChildClass('wrapper')} id="logo">
                    <img className={styles.logo} src={themeLogo} alt={'LOGO'}/>
                </div>
                <div className={getChildClass('policy')}>
                    {customPolicies
            .filter((policy) => !selectedPolicy || policy.policyType === selectedPolicy)
            .map(({ id, policyDisplayOption, policyType, policyTextValue }) => {
            const policyTitle = policiesTitle.filter((policies) => policies.id === policyType)[0]
                ?.value;
            // eslint-disable-next-line prefer-destructuring
            if (policyDisplayOption === PolicyDisplayOption.DO_NOT_DISPLAY) {
                return null;
            }
            return (<div className={getChildClass('policy')} key={id}>
                                    <div className={getChildClass('title')} id={policyType}>
                                        {policyTitle}
                                    </div>
                                    {policyTextValue &&
                    policyDisplayOption === 'customText' &&
                    renderRichText(policyTextValue)}
                                    {policyDisplayOption === 'url' && renderLink(policyTextValue, policyType)}
                                </div>);
        })}
                </div>
            </div>
        </div>);
};
export default Policies;
